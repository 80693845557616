import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@naf/button';
import { breakpoints, spacing, fontStyle } from '@naf/theme';
import { Grid, GridCol } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { HttpStatus } from '../../components/HttpStatus';
import { LayoutWithMainContent } from '../../components/layout/Layout';
import InternalServerErrorImage from '../../../assets/images/InternalServerError.svg';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';
import { MAIN_URL } from '../../lib/constants';

interface InternalServerErrorProps {
  withoutLayout?: boolean;
}

export const InternalServerError = ({ withoutLayout = false }: InternalServerErrorProps) =>
  withoutLayout ? (
    <InternalServerErrorInner />
  ) : (
    <LayoutWithMainContent
      title="NAF - 500"
      description="500 - Vi klarer ikke å vise siden"
      url=""
      imgUrl=""
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
    >
      <InternalServerErrorInner />
    </LayoutWithMainContent>
  );

const InternalServerErrorInner = () => {
  const title = 'Serverfeil';
  const description = 'Noe gikk galt under lastingen av nettsiden. Vennligst vent en liten stund og prøv igjen.';
  const buttonText = 'Gå til forsiden';

  const [baseUrl, setBaseUrl] = useState(MAIN_URL);

  useEffect(() => {
    setBaseUrl(window.location.origin);
  }, []);

  return (
    <Container role="main">
      <Wrapper>
        <Grid>
          <StyledGridCol s="12" m="12" l="6" xl="6">
            <HttpStatus code={500}>
              <Header variant={TextVariant.Header1}>{title}</Header>
              <StyledText tag="p" variant={TextVariant.ArticleTextLarge}>
                {description}
              </StyledText>
              <StyledButton variant="signature" size="large" href={baseUrl}>
                {buttonText}
              </StyledButton>
              <StyledButtonMobile variant="signature" size="small" href={baseUrl}>
                {buttonText}
              </StyledButtonMobile>
            </HttpStatus>
          </StyledGridCol>
          <StyledImageGridCol s="12" m="12" l="6" xl="6">
            <InternalServerErrorImage alt="Internal Server Error" />
          </StyledImageGridCol>
        </Grid>
      </Wrapper>
    </Container>
  );
};

export const Container = styled.main``;

export const StyledGridCol = styled(GridCol)`
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-bottom: ${spacing.space56};

  @media (max-width: ${breakpoints.m}) {
    align-items: center;
    padding-bottom: 0;
  }
`;

export const StyledImageGridCol = styled(GridCol)`
  display: flex;
  flex-direction: column;
  justify-content: end;
  @media (max-width: ${breakpoints.m}) {
    align-items: center;
  }
  svg {
    width: '100%';
    height: 'auto';
  }
`;

export const Wrapper = styled.div`
  margin-top: ${spacing.space48};
  @media (min-width: ${breakpoints.l}) {
    padding-top: ${spacing.space120};
    img {
      margin-left: ${spacing.space80};
    }
  }
  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space40};
  }
`;

export const StyledButton = styled(Button)`
  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

export const StyledButtonMobile = styled(Button)`
  display: none;
  @media (max-width: ${breakpoints.m}) {
    display: flex;
    margin-bottom: ${spacing.space16};
  }
`;

export const Header = styled(Text)`
  margin: 0;
  font-size: 4rem;
  line-height: 72px;
  @media (max-width: ${breakpoints.m}) {
    font-size: 2rem;
    line-height: 40px;
    margin-top: ${spacing.space24};
  }
`;

export const StyledText = styled(Text)`
  margin-top: ${spacing.space40};
  margin-bottom: ${spacing.space40};
  @media (max-width: ${breakpoints.m}) {
    font-size: ${fontStyle?.article?.articleText['font-size'] || '1.125rem'};
    line-height: ${fontStyle?.article?.articleText['line-height'] || '1.75rem'};
    margin-top: ${spacing.space24};
    margin-bottom: ${spacing.space24};
    text-align: center;
  }
`;
